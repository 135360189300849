@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);


body {
  --colors-bg--300: #1e181e;
  --colors-bg--500: #191a1e;
  --colors-primary--200: #f4908b;
  --colors-primary--300: #ea6f69;
  --colors-primary--500: #e8615a;
  --colors-primary--600: #9c3230;
  --colors-primary--700: #5e2122;
  --colors-primary--800: #451717;
  --colors-primary--900: #3c181a;
  --colors-secondary--500: #2be4ea;
  --colors-secondary--900: #295459;
  --colors-tertiary--500: #fed33f;
  --colors-on_bg--500: var(--colors-primary--500);
  --colors-on_tertiary--500: var(--colors-bg--300);
  --colors-on_primary--500: var(--colors-primary--200);
  --colors-active--500: #2bfea0;
  --fonts-primary: "Rajdhani", sans-serif;
  --fonts-secondary: "VT323", monospace;
  --ui-glow: 0 0 5px var(--colors-primary--500);
  --ui-glow-borders--500: 0 0 3px var(--colors-primary--500);
  --ui-glow-color: currentcolor;
  --ui-glow-text: -9px -6px 40px var(--ui-glow-color);
  --ui-glow-text--dimmed: -9px -6px 40px var(--ui-glow-color);
  --ui-elevation--1: 2px 2px 0 rgba(0, 0, 0, 0.8);
  --ui-notch-amount: 1rem;
  --ui-notch-hypotenuse: 22.627416px; /* hypothenuse of --ui-notch-amount */
  --ui-notch-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--ui-notch-amount) + 2px),
    calc(100% - var(--ui-notch-amount) + 2px) 100%,
    0 100%
  );

  background-color: var(--colors-bg--500);
  background-image: radial-gradient(
    ellipse at 33% 10%,
    #461616 0%,
    transparent 75%
  );
  background-repeat: no-repeat;
  color: var(--colors-on_bg--500);
  font-family: var(--fonts-primary);
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  text-align: center;
  animation: fadein 2s;
}

h2 {
  margin-top: 0;
  border-bottom: 1px solid black;
  transition: all .2s ease-in-out;
}

button {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  background: #f02849;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

button:hover {
  transform: scale(1.1);
}

label {
  text-align: left;
  font-size: 1.25em;
  color: #777776;
  display: block;
  transition: all .2s ease-in-out;
}

header {
  text-align: center;
}

main {
  padding: 3em;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines if necessary */
  justify-content: space-between;
  align-items: flex-start;
  animation: slidein 2s;
}

.left-column,
.center-column,
.right-column {
  flex: 0 0 30%; /* Flex grow, shrink, basis. 30% of the container's width, but will also shrink if necessary */
  min-width: 16em; /* Keep your original min-width, but consider adjusting if you still have problems */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  padding: 0 1.5em; /* Apply padding here rather than margin to avoid extra width */
  text-align: center;
}

/* Left Column */
#client-name {
  text-align: left;
  margin-bottom: 1em;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;  font-size: 1.25em;
  color: var(--light-gray-color);
}

select {
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
}

#contact-list {
  background-color: var(--colors-bg--300);
  border: 1px solid var(--colors-primary--600);
  clip-path: var(--ui-notch-path);
  padding-right: 0.5rem;
}

.contact-card {
  border: 1px solid var(--dark-black-color);
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.contact-card h3 {
  margin: 0 0 10px;
  font-size: 1.2em;
}

.contact-card p {
  margin: 0 0 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  color: #fed33f;
}

.dtmf-display-class {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

/* Center Column */
#call-timer {
  font-size: 2rem;
  color: #989898;
  text-align: center;
  /* Add more styles as needed */
}

#stepsOutput {
  font-size: 1.2rem;
  color: #989898;
  text-align: left;
  padding: 20px;
}

#stepsOutput ul {
  list-style-type: none; /* remove bullet points */
  padding-left: 0; /* remove default padding */
}

#stepsOutput li {
  margin-bottom: 15px; /* add some space between steps */
}

.step {
  color: #fed33f; /* gold color to match your textarea */
  font-weight: bold;
}

.time, .key {
  color: #fed33f; /* gold color to match your textarea */
  font-weight: normal;
  font-size: 1.5rem;
}

.container textarea {
  -webkit-text-size-adjust: 100%;
  color: rgb(55, 53, 47);
  fill: currentcolor;
  line-height: 1.5;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: auto;
  pointer-events: auto;
  font-size: 30px;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  outline: 0;
  box-sizing: border-box;
  max-width: 100%;
  width: 120%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  color: #fed33f;
  background-color: black;
  border-radius: 10px;
}


div#volume-indicators {
  padding: 10px;
  margin-top: 50px;
  width: 500px;
  text-align: left;
}

div#volume-indicators > div {
  margin-top: 10px;
  display: block;
  height: 20px;
  width: 0;
}

/* Right Column */
.right-column {
  padding: 0 1.5em;
}

#log {
  text-align: left;
  border: 1px solid var(--mid-gray-color);
  padding: 10px;
  height: 9.5em;
  overflow-y: scroll;
}

.log-entry {
  color: var(--mid-gray-color);
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif, fixed-width;
  font-size: 1.25em;
  line-height: 1.25em;
  margin-left: 1em;
  text-indent: -1.25em;
  width: 90%;
}

#call-history {
  background-color: var(--colors-bg--300);
  border: 1px solid var(--colors-primary--600);
  clip-path: var(--ui-notch-path);
  padding-right: 0.5rem;
}

#call-history p {
  margin: 0 0 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  color: #fed33f;
}


button.test-call-button {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: #2bfea0;
  background: #5e2122;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

button.test-call-button:hover {
  transform: scale(1.1);
  background-color: #8e4041;
}

button.add-to-contacts-button {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: #2be4ea;
  background: #5e2122;
  ;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

button.add-to-contacts-button:hover {
  transform: scale(1.1);
  background-color: #8e4041;
}

.delete-call-button {
  background: none;
  border: none;
  cursor: pointer;
}

#button-hangup-incoming {
  display: inline-block;
  background-color: var(--error-color);
  padding: 4px 30px;
  margin: 10px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

/* Other Styles */
.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

button:disabled{
  cursor: not-allowed;
}

.row {
  margin: 0 auto;
  width: 280px;
  clear: both;
  text-align: center;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.digit,
.dig {
  float: left;
  padding: 10px 30px;
  width: 30px;
  font-size: 2rem;
  background-color: var(--ui-glow-text);
  text-shadow: var(--ui-glow-text);
  cursor: pointer;
  color:#f2b900;
  transition: background-color 1s ease-in-out, box-shadow 0.3s ease-in-out; /* Add transition for smooth hover effect */
  border-radius: 5px; /* Add rounded corners */
}

.digit:hover,
.dig:hover {
  background-color: #ffce3d; /* Change to the desired lighter yellow color */
  box-shadow: 0 0 10px 5px #fdfdfd;
}

.sub {
  font-size: 0.0rem;
  color: rgb(0, 0, 0);
}

.container {
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 50px;
  border: 3px solid #5d2322;
  overflow: hidden;
  background: -webkit-gradient(linear, left top, right bottom, from(#000000), to(#000000));
  background: linear-gradient(to right bottom, #000000, #061018);
  width: 320px;
  height: 600px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 56px 72px -30px rgba(50, 55, 63, 0.66);
  box-shadow: 0px 56px 72px -30px rgba(50, 55, 63, 0.66);
}


#button-call {
  display: inline-block;
  background: linear-gradient(to bottom, #0eaa00, #0eaa00);
  padding: 4px 30px;
  margin: 10px;
  color: white;
  border-radius: 4px;
  float: left;
  cursor: pointer;
}

#hangup {
  display: inline-block;
  background: linear-gradient(to bottom, #ff0000, #ff0000);
  padding: 4px 30px;
  margin: 10px;
  color: white;
  border-radius: 4px;
  float: left;
  cursor: pointer;
}

.botrow {
  margin: 0 auto;
  width: 280px;
  clear: both;
  text-align: center;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.digit:active,
.dig:active {
  background-color: var(--ui-glow-text);
  text-shadow: var(--ui-glow-text);
}

#button-call:hover {
  background-color: var(--success-hover-color);
}

#hangup:hover {
  background-color: var(--error-hover-color);
}

.dig {
  float: left;
  padding: 10px 20px;
  margin: 10px;
  width: 30px;
  cursor: pointer;
}

#dtmf-display {
  text-align: left;
  border: 1px solid var(--mid-gray-color);
  padding: 10px;
  height: 2.5em;
  overflow-y: scroll;
  margin-bottom: 1em;
  color: var(--mid-gray-color);
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif, fixed-width;
  font-size: 1.25em;
  line-height: 1.25em;
  transition: all .2s ease-in-out;
}

#dtmf-display:hover {
  border-color: var(--highlight-color);
}

@media screen and (min-width: 600px) {
  .left-column,
  .center-column,
  .right-column {
    width: 30%;
  }
}